import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      className="w-full h-[70vh] svgHexagonInverso bg-cover bg-center bg-no-repeat relative before:absolute before:bg-[#00000091] before:w-full before:h-full"
      style={{ 
        backgroundImage: `url("${bgimg}")`,
        backgroundAttachment:'fixed'
        }}
    >
      <div className="flex flex-col  h-full pb-20 pt-0 md:pt-40 md:pb-0 justify-end md:justify-center items-center relative">
        <h1 className="text-white lg:mt-[80px]">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
  );
};

export default TransparentHeader;
